
export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/HomePage.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../components/about/AboutPage.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../components/legal/PrivacyPage.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../components/projects/ProjectPage.vue')
  },
  {
    path: '/project/:id',
    name: 'project',
    component: () => import('../components/projects/ProjectEntry.vue'),
    meta: {
      sitemap: {
        slugs: [
          {
            id: 0,
            title: 'Simly',
            priority: 1.0
          },
          {
            id: 1,
            title: 'Supporting Social Interaction in VR (Research)'
          },
          {
            id: 2,
            title: 'TelMee'
          },
          {
            id: 3,
            title: 'Virtual Reality And Empathy (Research)'
          }
        ]
      }
    }
  },
  {
    path: '*',
    redirect: 'home'
  }
]
