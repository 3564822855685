<template>
  <div id="app">
    <v-app>
    <cookie-law theme="mytheme">
      <div slot="message">
      This site uses Cookies to manage your browsing experience and collect analytic usage data. Consult the <router-link to="privacy">Privacy Policy</router-link> for more information.
    </div>
    </cookie-law>
    <MenuSidebar />
      <main class="padded">
        <router-view></router-view>
      </main>
    </v-app>
  </div>
</template>

<script>
import MenuSidebar from '@/components/MenuSidebar.vue'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'app',
  components: {
    MenuSidebar,
    CookieLaw
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: white;
}
.danger-alert {
  color: red;
}
.padded {
  padding-left: 56px;
}
.Cookie--mytheme {
  background:rgb(54,54,54);
  padding: 1.250em;
}
.Cookie--mytheme .Cookie__button {
  color:rgb(54,54,54);
  border-radius: 25px;
  padding: 10px;
  width: 150px;
  background: rgba(230,177,63,1);
}
.Cookie--mytheme div.Cookie__button:hover {
  background: rgba(230,177,63,0.5);
}
#fp-nav ul li a span, 
.fp-slidesNav ul li a span {
  background: radial-gradient(circle, 
    rgba(230,177,63,1) 10%, 
    rgba(54,54,54,1) 100%, 
    rgba(54,54,54,1) 100%
  );
}
</style>
