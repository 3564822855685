import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import { sync } from 'vuex-router-sync'

import store from '@/store/store'

import 'fullpage.js/vendors/scrolloverflow'
import 'fullpage.js/dist/fullpage.css'
import VueFullPage from 'vue-fullpage.js'
import VueKinesis from 'vue-kinesis'

import {vueAccordion} from 'vue-accordion'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = true
Vue.component('vue-accordion', vueAccordion)
Vue.use(VueFullPage)
Vue.use(VueKinesis)
Vue.use(Vuetify)

sync(store, router)

export const EventBus = new Vue({
  el: '#app',
  router,
  store,
  vuetify: new Vuetify({
    theme: {
      dark: true
    }
  }),
  template: '<App/>',
  components: { App }
})
