<template>
  <v-navigation-drawer class="fill"
    app
    permanent
    stateless
    v-touch="{
      left: () => setDrawerSize(true),
      right: () => setDrawerSize(false)
    }"
    :mini-variant="mini"
  >
    <v-list>
      <v-list-item class="px-2">
          <v-list-item-avatar 
          class="rounded-0"
          style="width:100%"
          v-bind:class="{ pulse: mini }"
          @click="onClickStop()">
            <v-img class="rounded-0" contain :src="logo"></v-img>
          </v-list-item-avatar>
        </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list
      nav
      dense
    >
      <v-list-item 
        v-for="entry in navigation"
        :key="entry.title"
        :to="entry.url">
        <v-list-item-icon>
          <v-icon>{{entry.icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{entry.title}}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list class="bottom"
      nav
      dense
    >
      <v-list-item 
        v-for="entry in social"
        :key="entry.title"
        :href="entry.url"
        target="_blank">
        <v-list-item-icon>
          <v-icon>{{entry.icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{entry.title}}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {APP_TITLE, NAVIGATION, SOCIALS} from '@/config.js'

export default {
  computed: {

  },
  data: () => ({
    title: APP_TITLE,
    mini: true,
    logo: '/images/logo.png',
    navigation: NAVIGATION,
    social: SOCIALS
  }),
  methods: {
    home () {
      if (this.$route.name !== 'home') {
        this.$router.push({
          name: 'home'
        })
      }
    },
    onClickStop () {
      this.setDrawerSize(!this.mini)
    },
    setDrawerSize (mini) {
      this.mini = mini
      this.logo = `/images/logo${mini ? '' : '-full'}.png`
    },
    refresh () {
      this.$router.refresh()
    }
  },
  created () {
    
  },
  destroyed () {
   
  },
  mounted () {
    
  }
}
</script>
<style scoped>
.fill {
  height: 100vh;
}

.bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
}

#no-background-hover::before {
  background-color: transparent !important;
}

.pulse {
  box-shadow: 0 0 0 0 rgba(232,178,62, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(232,178,62, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(232,178,62, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(232,178,62, 0);
  }
}
</style>
