/* eslint-disable */
module.exports = {
  APP_TITLE: 'Portfolio Kaz Voeten',
  API_URL: 'https://api.kazvoeten.com/',
  FULLPAGE_LICENSE: 'BB6F26F9-0FD64A1C-BEDE5FAF-022967ED',
  NAVIGATION: [
    {title: 'Home', url: '/', icon: 'mdi-home'},
    {title: 'About me', url: '/about', icon: 'mdi-google-street-view'},
    {title: 'Projects', url: '/projects', icon: 'mdi-book-open-page-variant-outline'}
  ],
  SOCIALS: [
    {title: 'LinkedIn', url: 'https://www.linkedin.com/in/kvoeten/', icon: 'mdi-linkedin'},
    {title: 'Github', url: 'https://github.com/kvoeten', icon: 'mdi-github'},
    {title: 'Instagram', url: 'https://www.instagram.com/kaz_voeten/', icon: 'mdi-instagram'},
    {title: 'Youtube', url: 'https://www.youtube.com/channel/UCWIjigRiDwzBVYh3EK95anA?view_as=subscriber', icon: 'mdi-youtube'},
    {title: 'Facebook', url: 'https://www.facebook.com/kaz.voeten', icon: 'mdi-facebook'}
  ]
}
